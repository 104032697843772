import { ArrowRightOutlined, CopyOutlined, SettingOutlined } from '@ant-design/icons'
import { Button, Drawer, Tooltip, Alert } from 'antd'
import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import PayoutSettings from '../PayoutSettings'

const { REACT_APP_AFFILIATE_API_URL } = process.env

export const OptInAffiliateDetails = ({ userData, optIn }) => {
  const queryClient = new useQueryClient()
  const [affiliateCopied, setAffiliateCopied] = useState({})
  const [paymentsOpen, setPaymentsOpen] = useState(false)

  const copyAffiliateLink = i => {
    setAffiliateCopied(prev => ({ ...prev, [i]: true }))
    setTimeout(() => {
      setAffiliateCopied(prev => ({ ...prev, [i]: false }))
    }, 1000)
  }

  return (
    <Wrapper>
      <div className='info-section affiliate'>
        {userData?.trolleyStatus === 'active' && (
          <>
            {/* AWIN */}
            {optIn.campaign.brand.extraData.awinId && (
              <>
                <h2>Affiliate Links - Awin</h2>
                <div className='commission'>
                  <label>
                    Commission:{' '}
                    <b>{optIn.campaign.metaData.affiliateCommission || optIn.awinCommission}%</b>
                  </label>
                </div>
                {optIn.campaign.metaData.affiliateLinks?.length &&
                  optIn.campaign.metaData.affiliateLinks?.map((affiliateLink, i) => {
                    const url = `${REACT_APP_AFFILIATE_API_URL}/aw-${optIn.id}-${i + 1}`
                    return (
                      <div key={i} className='affiliate-links'>
                        <CopyToClipboard text={url}>
                          <Tooltip trigger='click' open={affiliateCopied[i]} title='Copied!'>
                            <Button
                              type='link'
                              className='affiliate-btn'
                              onClick={() => copyAffiliateLink(i)}>
                              {url}
                              <CopyOutlined />
                            </Button>
                          </Tooltip>
                        </CopyToClipboard>
                        <p>{affiliateLink.description}</p>
                      </div>
                    )
                  })}
                <Link to='/affiliate/awin'>
                  Affiliate Dashboard <ArrowRightOutlined />
                </Link>
              </>
            )}

            {/* IMPACT RADIUS */}
            {optIn.campaign.brand.extraData.impactRadiusId && (
              <>
                <h2>Affiliate Links - Impact Radius</h2>
                {optIn.campaign.metaData.impactRadiusAffiliateLinks?.length &&
                  optIn.campaign.metaData.impactRadiusAffiliateLinks?.map((affiliateLink, i) => {
                    const url = `${REACT_APP_AFFILIATE_API_URL}/ir-${optIn.id}-${i + 1}`
                    return (
                      <div key={i} className='affiliate-links'>
                        <CopyToClipboard text={url}>
                          <Tooltip trigger='click' open={affiliateCopied[i]} title='Copied!'>
                            <Button
                              type='link'
                              className='affiliate-btn'
                              onClick={() => copyAffiliateLink(i)}>
                              {url}
                              <CopyOutlined />
                            </Button>
                          </Tooltip>
                        </CopyToClipboard>
                        <p>{affiliateLink.description}</p>
                      </div>
                    )
                  })}
                <Link to='/affiliate/impact'>
                  Affiliate Dashboard <ArrowRightOutlined />
                </Link>
              </>
            )}

            {/* CJ AFFILIATE */}
            {optIn.campaign.brand.extraData?.cjId && (
              <div className='affiliate-program'>
                <h2>Affiliate Links - CJ Affiliate</h2>
                {optIn.campaign.metaData.cjAffiliateLinks?.length &&
                  optIn.campaign.metaData.cjAffiliateLinks?.map((affiliateLink, i) => {
                    const url = `${REACT_APP_AFFILIATE_API_URL}/cj-${optIn.id}-${i + 1}`
                    return (
                      <div key={affiliateLink.url || `${url}-${i}`} className='affiliate-links'>
                        <CopyToClipboard text={url}>
                          <Tooltip trigger='click' open={affiliateCopied[i]} title='Copied!'>
                            <Button
                              type='link'
                              className='affiliate-btn'
                              onClick={() => copyAffiliateLink(i)}>
                              {url}
                              <CopyOutlined />
                            </Button>
                          </Tooltip>
                        </CopyToClipboard>
                        <p>{affiliateLink.description}</p>
                      </div>
                    )
                  })}
                <Link to='/affiliate/cj'>
                  Affiliate Dashboard <ArrowRightOutlined />
                </Link>
              </div>
            )}

            {/* RAKUTEN */}
            {optIn.campaign.brand.extraData.rakutenId &&
              !!optIn.campaign.metaData.rakutenAffiliateLinks?.[0]?.url && (
                <>
                  <h2>Affiliate Links - Rakuten</h2>
                  {!!optIn.campaign.metaData.affiliateCommission && (
                    <div className='commission'>
                      <label>
                        Commission: <b>{optIn.campaign.metaData.affiliateCommission}%</b>
                      </label>
                    </div>
                  )}
                  {optIn.campaign.metaData.rakutenAffiliateLinks.map((affiliateLink, i) => {
                    const url = `${REACT_APP_AFFILIATE_API_URL}/rk-${optIn.id}-${i + 1}`
                    return (
                      <div className='awin-links' key={url}>
                        <CopyToClipboard text={url}>
                          <Tooltip trigger='click' open={affiliateCopied[i]} title='Copied!'>
                            <span
                              className='affiliate-btn'
                              style={{
                                cursor: 'pointer',
                                color: '#027DF0',
                                overflowWrap: 'break-word',
                              }}
                              onClick={() => copyAffiliateLink(i)}
                              onKeyDown={() => copyAffiliateLink(i)}
                              role='button'
                              tabIndex={0}>
                              {url}
                              <CopyOutlined />
                            </span>
                          </Tooltip>
                        </CopyToClipboard>
                        <p>{affiliateLink.description}</p>
                      </div>
                    )
                  })}
                  <Link to='/affiliate/rakuten'>
                    Affiliate Dashboard <ArrowRightOutlined />
                  </Link>
                </>
              )}

            {/* CREATOR.CO */}
            {!!optIn.creatorAffiliateLinks?.length && (
              <>
                <h2>Affiliate Links - Creator.co</h2>
                <div className='creator-affiliate-commission'>
                  {optIn?.creatorAffiliateLinks?.map((link, i) => {
                    const url = `${REACT_APP_AFFILIATE_API_URL}/cr-${optIn.id}-${link?.brandAffiliateLinkId}`
                    return (
                      <div className='affiliate-links' key={i}>
                        <CopyToClipboard text={url}>
                          <Tooltip trigger='click' open={affiliateCopied[link?.id]} title='Copied!'>
                            <Button
                              type='link'
                              className='affiliate-btn'
                              onClick={() => copyAffiliateLink(link?.id)}>
                              <CopyOutlined />
                              Get Shareable Link
                            </Button>
                          </Tooltip>
                        </CopyToClipboard>
                        <p className='urlPath'>Links to: {link?.brandAffiliateLink?.urlPath} </p>
                        <label>
                          <strong>{link?.brandAffiliateLink?.commission * 100}%</strong>{' '}
                          <span className='affiliate-commission'>commission</span>
                        </label>
                        <div className='affiliate-description'>
                          {link?.brandAffiliateLink?.description}
                        </div>
                      </div>
                    )
                  })}
                </div>
                <Link to='/affiliate/creator'>
                  Affiliate Dashboard <ArrowRightOutlined />
                </Link>
              </>
            )}

            {/* SHAREASALE */}
            {optIn.campaign.brand.extraData.shareasaleId && (
              <>
                <h2>Affiliate Links - ShareASale</h2>
                <div className='commission'>
                  <label>
                    Commission: <b>{optIn.campaign.metaData.affiliateCommission}%</b>
                  </label>
                </div>
                {optIn.campaign.metaData.shareasaleAffiliateLinks?.map((affiliateLink, i) => {
                  const url = `${REACT_APP_AFFILIATE_API_URL}/ss-${optIn.id}-${i + 1}`
                  return (
                    <div key={i} className='affiliate-links'>
                      <CopyToClipboard text={url}>
                        <Tooltip trigger='click' open={affiliateCopied[i]} title='Copied!'>
                          <span
                            style={{
                              cursor: 'pointer',
                              color: '#027DF0',
                              overflowWrap: 'break-word',
                            }}
                            className='affiliate-btn'
                            onClick={() => copyAffiliateLink(i)}
                            onKeyDown={() => copyAffiliateLink(i)}
                            role='button'
                            tabIndex={0}>
                            {`${optIn.campaign.brand.website}${affiliateLink.url}/${optIn.userId}`}{' '}
                            <CopyOutlined />
                          </span>
                        </Tooltip>
                      </CopyToClipboard>
                      <p style={{ marginTop: '10px' }}>{affiliateLink.description}</p>
                    </div>
                  )
                })}
                <Link to='/affiliate/shareasale'>
                  Affiliate Dashboard <ArrowRightOutlined />
                </Link>
              </>
            )}
          </>
        )}

        {userData?.trolleyStatus !== 'active' && (
          <>
            <Alert
              type='error'
              message='No payout method connected'
              description='Please add your payout settings in order to receive payment for your affiliate sales.'
              showIcon
            />
            <Button type='link' icon={<SettingOutlined />} onClick={() => setPaymentsOpen(true)}>
              Payout Settings
            </Button>
            <Drawer
              onClose={() => {
                setPaymentsOpen(false)
                queryClient.invalidateQueries('user')
              }}
              open={paymentsOpen}
              footer={null}
              width={window.innerWidth > 600 ? 600 : '100vw'}>
              <PayoutSettings />
            </Drawer>
          </>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .affiliate-btn {
    padding-left: 0px !important;
  }
`
